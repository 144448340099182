// src/app.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Browse from './pages/Browse';
import Login from './components/auth/Login';
import CustomerSignup from './components/auth/CustomerSignup';
import VenueSignup from './components/auth/VenueSignup';
import CustomerDashboard from './components/customer/CustomerDashboard';
import VenueDashboard from './components/venue/VenueDashboard';
import TicketDetails from './components/customer/TicketDetails';
import EventManagement from './pages/EventManagement';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import WalletConnection from './components/WalletConnection';
import Return from './components/customer/Return';
import ScannerPage from './pages/ScannerPage';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/browse" element={<Browse />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup/customer" element={<CustomerSignup />} />
        <Route path="/signup/venue" element={<VenueSignup />} />
        <Route path="/signup" element={<WalletConnection />} />
        <Route path="/customer-dashboard" element={<ProtectedRoute role="customer"><CustomerDashboard /></ProtectedRoute>} />
        <Route path="/venue-dashboard" element={<ProtectedRoute role="venue"><VenueDashboard /></ProtectedRoute>} />
        <Route path="/tickets/:id" element={<TicketDetails />} />
        <Route path="/event-management/:id" element={<EventManagement />} />
        <Route path="/return" element={<Return />} />
        <Route path="/scan-ticket/:id" element={<ScannerPage />} /> {/* Add this line */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
