// src/pages/Home.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios';

const Home = () => {
  const navigate = useNavigate();
  const { publicKey } = useWallet();

  const checkUserRole = async () => {
    try {
      const response = await axios.get('/auth/check-signed-in');
      const { signedIn, role } = response.data;
      if (signedIn) {
        if (role === 'customer') {
          navigate('/customer-dashboard');
        } else if (role === 'venue') {
          navigate('/venue-dashboard');
        }
      }
    } catch (error) {
      console.error('Failed to check sign-in status', error);
    }
  };

  React.useEffect(() => {
    checkUserRole();
  }, []);

  return (
    <Container>
      <Typography variant="h2" align="center" gutterBottom>
        Welcome to RhealAccess
      </Typography>
      <Typography variant="h5" align="center" paragraph>
        Buy and sell event tickets securely using blockchain technology.
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <Button variant="contained" color="primary" component={Link} to="/browse">
            Browse Tickets
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" component={Link} to="/login">
            Login
          </Button>
        </Grid>
      </Grid>
      <Box mt={4} textAlign="center">
        <Typography variant="h6">Or sign up as:</Typography>
        <Button variant="contained" color="primary" component={Link} to="/signup/customer" sx={{ m: 1 }}>
          Customer
        </Button>
        <Button variant="contained" color="secondary" component={Link} to="/signup/venue" sx={{ m: 1 }}>
          Venue
        </Button>
      </Box>
    </Container>
  );
};

export default Home;
