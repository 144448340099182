// rhealaccess-ticketing/src/components/venue/VenueDashboard.js

import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';

const VenueDashboard = () => {
    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await axios.get('/api/events');
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handleCreateEvent = async () => {
        try {
            const response = await axios.post('/api/events', { name: eventName, date: eventDate });
            setEvents([...events, response.data]);
            setEventName('');
            setEventDate('');
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Venue Dashboard</Typography>
            <Box mb={3}>
                <Typography variant="h6">Create Event</Typography>
                <TextField label="Event Name" value={eventName} onChange={(e) => setEventName(e.target.value)} fullWidth margin="normal" />
                <TextField label="Event Date" type="datetime-local" value={eventDate} onChange={(e) => setEventDate(e.target.value)} fullWidth margin="normal" InputLabelProps={{ shrink: true }} />
                <Button variant="contained" color="primary" onClick={handleCreateEvent}>Create Event</Button>
            </Box>
            <Typography variant="h6">Upcoming Events</Typography>
            <Grid container spacing={3}>
                {events.map((event) => (
                    <Grid item xs={12} sm={6} md={4} key={event.id}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">{event.name}</Typography>
                                <Typography>{new Date(event.date).toLocaleString()}</Typography>
                                <Button variant="contained" color="primary" component={Link} to={`/event-management/${event.id}`}>Manage Event</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default VenueDashboard;
