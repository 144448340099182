// src/components/common/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import WalletMenu from './WalletMenu';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { signedIn, role } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = async () => {
    try {
      await axios.get('/auth/logout');
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          RhealAccess
        </Typography>
        <Button color="inherit" component={Link} to="/">
          Home
        </Button>
        <Button color="inherit" component={Link} to="/browse">
          Browse
        </Button>
        {!signedIn && (
          <>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" onClick={handleMenuOpen}>
              Sign Up
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/signup/customer" onClick={handleMenuClose}>
                Customer
              </MenuItem>
              <MenuItem component={Link} to="/signup/venue" onClick={handleMenuClose}>
                Venue
              </MenuItem>
            </Menu>
          </>
        )}
        {signedIn && role === 'customer' && (
          <Button color="inherit" component={Link} to="/customer-dashboard">
            Dashboard
          </Button>
        )}
        {signedIn && role === 'venue' && (
          <Button color="inherit" component={Link} to="/venue-dashboard">
            Dashboard
          </Button>
        )}
        {signedIn && (
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        )}
        <WalletMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
