// rhealaccess-ticketing/src/components/customer/return.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography } from '@mui/material';

const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        const fetchSessionStatus = async () => {
            try {
                const response = await axios.get(`stripe/session-status?session_id=${sessionId}`);
                setStatus(response.data.status);
                setCustomerEmail(response.data.customer_email);
            } catch (error) {
                console.error('Error fetching session status:', error);
            }
        };

        fetchSessionStatus();
    }, []);

    useEffect(() => {
        if (status === 'paid') {
            setTimeout(() => {
                navigate('/customer-dashboard');
            }, 10000);
        }
    }, [status, navigate]);

    return (
        <div>
            {status === 'paid' ? (
                <Typography variant="h5">Thank you for your purchase! A confirmation email has been sent to {customerEmail}.</Typography>
            ) : (
                <Typography variant="h5">Processing your purchase...</Typography>
            )}
        </div>
    );
};

export default Return;
