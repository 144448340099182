// src/components/CheckoutButton.js

import React from 'react';
import { Button } from '@mui/material';
import axios from 'axios';

const CheckoutButton = ({ ticketId }) => {
    const handleClick = async () => {
        try {
            const response = await axios.post('/stripe/create-checkout-session', { ticketId, quantity: 1 });
            const { sessionId } = response.data;
            const stripe = window.Stripe('pk_test_51PiPRHIaCJL4XID3cisruOFYX8bEzNY1tTAt4jhLpjR0M5xrD9HwDZp8kVFzMcwpLhFlmNZhm8WKWTVUN1JWdWZu00je6x1v2w'); // Replace with your actual public key
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                console.error('Error redirecting to checkout:', error);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <Button variant="contained" color="primary" onClick={handleClick}>
            Buy Ticket
        </Button>
    );
};

export default CheckoutButton;
