// src/components/venue/QRScanner.js
import React from 'react';
import QrReader from 'react-qr-scanner';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import axios from 'axios';

const QRScanner = ({ eventId, open, onClose }) => {
  const handleScan = async (data) => {
    if (data) {
      try {
        const response = await axios.post('/api/validate-ticket', {
          ticketId: data.text,
          eventId: eventId,
        });

        if (response.data.success) {
          alert('Ticket validated successfully!');
        } else {
          alert('Failed to validate ticket: ' + response.data.message);
        }
      } catch (error) {
        console.error('Error validating ticket:', error);
        alert('An error occurred while validating the ticket.');
      }
    }
  };

  const handleError = (err) => {
    console.error('Error scanning QR code:', err);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Scan QR Code</DialogTitle>
      <DialogContent>
        <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRScanner;
