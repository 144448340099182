// src/components/customer/TicketDetails.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import CheckoutButton from '../CheckoutButton';

const TicketDetails = () => {
    const { id } = useParams();
    const [ticket, setTicket] = useState(null);

    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const response = await axios.get(`/api/tickets/${id}`);
                setTicket(response.data);
                console.log('Fetched ticket:', response.data); // Debugging line
            } catch (error) {
                console.error('Error fetching ticket:', error);
            }
        };
        fetchTicket();
    }, [id]);

    return (
        <Container>
            {ticket ? (
                <Box>
                    <Typography variant="h4" gutterBottom> Ticket Details </Typography>
                    <Typography variant="h6">Event: {ticket.event.name}</Typography>
                    <Typography variant="h6">Price: {ticket.price} USD</Typography>
                    <Typography variant="h6">Status: {ticket.status}</Typography>
                    <CheckoutButton ticketId={ticket.id} />
                </Box>
            ) : (
                <Typography variant="h6">Loading...</Typography>
            )}
        </Container>
    );
};

export default TicketDetails;
