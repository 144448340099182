// src/components/WalletConnection.js
import React, { useCallback, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const WalletConnection = () => {
  const { publicKey, signMessage } = useWallet();
  const navigate = useNavigate();

  const handleSignin = useCallback(async () => {
    if (!publicKey || !signMessage) return;
    const nonce = 'some-unique-nonce'; // generate or fetch nonce from backend
    try {
      const message = new TextEncoder().encode(`Sign this message to authenticate: ${nonce}`);
      const signature = await signMessage(message);
      const response = await axios.post('/auth/solana-signin', {
        publicKey: publicKey.toBase58(),
        signature: Array.from(signature),
        nonce,
      });
      if (response.data.message === 'Sign-in successful') {
        const role = response.data.role;
        if (role === 'customer') {
          navigate('/customer-dashboard');
        } else if (role === 'venue') {
          navigate('/venue-dashboard');
        } else {
          navigate('/signup');
        }
      } else {
        navigate('/signup');
      }
    } catch (error) {
      console.error('Sign-in failed', error);
    }
  }, [publicKey, signMessage, navigate]);

  useEffect(() => {
    const checkUserExists = async () => {
      if (publicKey) {
        const response = await axios.get('/auth/user-exists', {
          params: { publicKey: publicKey.toBase58() },
        });
        if (!response.data.exists) {
          navigate('/signup');
        } else {
          handleSignin();
        }
      }
    };
    checkUserExists();
  }, [publicKey, navigate, handleSignin]);

  return (
    <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
      <WalletMultiButton />
      {publicKey && (
        <Button variant="contained" color="primary" onClick={handleSignin}>
          Sign in with Solana
        </Button>
      )}
    </Box>
  );
};

export default WalletConnection;
