import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const footer = () => {
  return (
    <Box sx={{ backgroundColor: '#1976d2', color: 'white', padding: '10px 0', marginTop: '20px' }}>
      <Container>
        <Typography variant="body1" align="center" gutterBottom>
          &copy; {new Date().getFullYear()} RhealAccess
        </Typography>
      </Container>
    </Box>
  );
};

export default footer;
