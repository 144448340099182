// src/pages/browse.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CheckoutButton from '../components/CheckoutButton';

const Browse = () => {
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get('/api/tickets');
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };
    fetchTickets();
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Browse Tickets
      </Typography>
      {tickets.length > 0 ? (
        <Grid container spacing={4}>
          {tickets.map((ticket) => (
            <Grid item key={ticket.id} xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5">
                    {ticket.event ? ticket.event.name : 'No Event Name'}
                  </Typography>
                  <Typography>{ticket.price} USD</Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/tickets/${ticket.id}`}
                      sx={{ marginRight: 1 }}
                    >
                      View Details
                    </Button>
                    <CheckoutButton ticketId={ticket.id} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box mt={4} textAlign="center">
          <Typography variant="h6">No tickets for sale</Typography>
        </Box>
      )}
    </Container>
  );
};

export default Browse;
