// src/components/customer/CustomerDashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import axios from 'axios';

const CustomerDashboard = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get('/api/my-tickets');
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };
    fetchTickets();
  }, []);

  const handleOpenDialog = (ticket) => {
    setSelectedTicket(ticket);
  };

  const handleCloseDialog = () => {
    setSelectedTicket(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Customer Dashboard
      </Typography>
      {tickets.length > 0 ? (
        <Grid container spacing={3}>
          {tickets.map((ticket) => (
            <Grid item xs={12} sm={6} md={4} key={ticket.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{ticket.event.name}</Typography>
                  <Typography>{new Date(ticket.event.date).toLocaleString()}</Typography>
                  <Typography>{ticket.price} USD</Typography>
                  <Typography>Status: {ticket.status}</Typography>
                  <Button variant="contained" color="primary" onClick={() => handleOpenDialog(ticket)}>
                    View Ticket
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box mt={4} textAlign="center">
          <Typography variant="h6" gutterBottom>
            You don't have any tickets yet.
          </Typography>
          <Button variant="contained" color="primary" component={Link} to="/browse">
            Browse Tickets
          </Button>
        </Box>
      )}
      <Dialog open={!!selectedTicket} onClose={handleCloseDialog}>
        <DialogTitle>Ticket QR Code</DialogTitle>
        <DialogContent>
          {selectedTicket && (
            <>
              <Typography variant="h6">{selectedTicket.event.name}</Typography>
              <QRCode value={selectedTicket.id} size={256} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CustomerDashboard;
