// src/pages/EventManagement.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Grid, Card, CardContent } from '@mui/material';
import axios from 'axios';
import QRScanner from '../components/venue/QRScanner';

const EventManagement = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [price, setPrice] = useState('');
  const [maxQuantity, setMaxQuantity] = useState('');
  const [section, setSection] = useState('');
  const [row, setRow] = useState('');
  const [seat, setSeat] = useState('');
  const [scannerOpen, setScannerOpen] = useState(false);

  useEffect(() => {
    fetchEvent();
    fetchTickets();
  }, [id]);

  const fetchEvent = async () => {
    try {
      const response = await axios.get(`/api/events/${id}`);
      setEvent(response.data);
    } catch (error) {
      console.error('Error fetching event:', error);
    }
  };

  const fetchTickets = async () => {
    try {
      const response = await axios.get(`/api/tickets`, { params: { eventId: id } });
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const handleAddTicket = async () => {
    try {
      const response = await axios.post('/api/tickets', {
        eventId: id,
        price,
        maxQuantity,
        section,
        row,
        seat,
        status: 'available',
      });
      setTickets([...tickets, response.data]);
      setPrice('');
      setMaxQuantity('');
      setSection('');
      setRow('');
      setSeat('');
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  return (
    <Container>
      {event ? (
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Event: {event.name}
          </Typography>
          <Typography variant="h6">Date: {new Date(event.date).toLocaleString()}</Typography>
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Create Ticket
            </Typography>
            <TextField label="Price" type="number" value={price} onChange={(e) => setPrice(e.target.value)} fullWidth margin="normal" />
            <TextField label="Max Quantity" type="number" value={maxQuantity} onChange={(e) => setMaxQuantity(e.target.value)} fullWidth margin="normal" />
            <TextField label="Section" value={section} onChange={(e) => setSection(e.target.value)} fullWidth margin="normal" />
            <TextField label="Row" value={row} onChange={(e) => setRow(e.target.value)} fullWidth margin="normal" />
            <TextField label="Seat" value={seat} onChange={(e) => setSeat(e.target.value)} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleAddTicket}>
              Create Ticket
            </Button>
          </Box>
          <Box mt={4}>
            <Typography variant="h6">Tickets</Typography>
            <Grid container spacing={3}>
              {tickets.map((ticket) => (
                <Grid item xs={12} sm={6} md={4} key={ticket.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5">{ticket.event ? ticket.event.name : 'No event name'}</Typography>
                      <Typography>Price: {ticket.price} USD</Typography>
                      <Typography>Status: {ticket.status}</Typography>
                      {ticket.section && <Typography>Section: {ticket.section}</Typography>}
                      {ticket.row && <Typography>Row: {ticket.row}</Typography>}
                      {ticket.seat && <Typography>Seat: {ticket.seat}</Typography>}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={4}>
            <Button variant="contained" color="secondary" onClick={() => setScannerOpen(true)}>
              Open QR Scanner
            </Button>
          </Box>
          <QRScanner eventId={id} open={scannerOpen} onClose={() => setScannerOpen(false)} />
        </Box>
      ) : (
        <Typography variant="h6">Loading...</Typography>
      )}
    </Container>
  );
};

export default EventManagement;
