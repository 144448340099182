// src/pages/ScannerPage.js
import React, { useState } from 'react';
import { Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import QrReader from 'react-qr-scanner';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ScannerPage = () => {
  const { id } = useParams(); // Get event ID from the URL
  const [scannedData, setScannedData] = useState(null);
  const [open, setOpen] = useState(true);

  const handleScan = (data) => {
    if (data) {
      setScannedData(data);
      handleTicketValidation(data);
    }
  };

  const handleError = (err) => {
    console.error('Error scanning QR code:', err);
  };

  const handleTicketValidation = async (ticketId) => {
    try {
      const response = await axios.post('/api/validate-ticket', { ticketId, eventId: id });
      if (response.data.success) {
        alert('Ticket validated successfully');
      } else {
        alert('Invalid ticket');
      }
    } catch (error) {
      console.error('Error validating ticket:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Scan Ticket
      </Typography>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Scan QR Code</DialogTitle>
        <DialogContent>
          <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ScannerPage;
