// src/components/auth/VenueSignup.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';

const VenueSignup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { publicKey } = useWallet();

  const handleSignup = async () => {
    try {
        const response = await axios.post('/auth/signup/venue', {
            email,
            password,
            publickey: publicKey ? publicKey.toBase58() : null,
        });
        if (response.data.role === 'venue') {
            navigate('/venue-dashboard');
        } else {
            navigate('/signup');
        }
    } catch (error) {
        console.error('Signup failed:', error);
    }
};


  return (
    <Container maxWidth="xs">
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>Venue Signup</Typography>
        <TextField label="Email" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Password" type="password" fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
        <Button variant="contained" color="primary" fullWidth onClick={handleSignup}>Sign Up</Button>
      </Box>
    </Container>
  );
};

export default VenueSignup;
