// src/components/common/WalletMenu.js
import React, { useState, useCallback, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Box, Button, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const WalletMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const { publicKey, signMessage } = useWallet();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchNonce = async () => {
    try {
      const response = await axios.get('/auth/nonce');
      return response.data.nonce;
    } catch (error) {
      console.error('Failed to fetch nonce', error);
      return null;
    }
  };

  const handleSignIn = useCallback(async () => {
    if (!publicKey || !signMessage) return;
    const nonce = await fetchNonce();
    if (!nonce) return;
    try {
      const message = new TextEncoder().encode(`Sign this message to authenticate: ${nonce}`);
      const signature = await signMessage(message);
      const response = await axios.post('/auth/solana-signin', {
        publicKey: publicKey.toBase58(),
        signature: Array.from(signature),
        nonce,
      });
      if (response.data.message === 'Sign-in successful') {
        setSignedIn(true);
      }
    } catch (error) {
      console.error('Sign-in failed', error);
    }
  }, [publicKey, signMessage]);

  const handleLogout = async () => {
    try {
      await axios.get('/auth/logout');
      setSignedIn(false);
      navigate('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  useEffect(() => {
    if (publicKey) {
      const checkSignedIn = async () => {
        try {
          const response = await axios.get('/auth/check-signed-in', { params: { publicKey: publicKey.toBase58() } });
          if (response.data.signedIn) {
            setSignedIn(true);
          }
        } catch (error) {
          console.error('Failed to check sign-in status', error);
        }
      };
      checkSignedIn();
    }
  }, [publicKey]);

  return (
    <Box>
      <IconButton color="inherit" onClick={handleClick}>
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'wallet-button' }}
        PaperProps={{ style: { maxHeight: '200px' } }}
      >
        <MenuItem onClick={(e) => e.stopPropagation()}>
          <WalletMultiButton />
        </MenuItem>
        {publicKey && !signedIn && (
          <MenuItem onClick={(e) => { e.stopPropagation(); handleSignIn(); }}>
            <Button variant="contained" color="primary">Sign In with Solana</Button>
          </MenuItem>
        )}
        {signedIn && (
          <>
            <MenuItem>
              <Typography>Signed in as {`${publicKey.toBase58().slice(0, 3)}...${publicKey.toBase58().slice(-3)}`}</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Button variant="contained" color="secondary">Logout</Button>
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default WalletMenu;
