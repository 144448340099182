// src/hooks/useAuth.js
import { useEffect, useState } from 'react';
import axios from 'axios';

const useAuth = () => {
  const [auth, setAuth] = useState({ checked: false, signedIn: false, role: null });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/auth/check-signed-in');
        setAuth({ checked: true, signedIn: response.data.signedIn, role: response.data.role });
      } catch (error) {
        console.error('Failed to check auth status', error);
        setAuth({ checked: true, signedIn: false, role: null });
      }
    };
    checkAuth();
  }, []);

  return auth;
};

export default useAuth;
