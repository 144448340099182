// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ProtectedRoute = ({ children, role }) => {
  const { checked, signedIn, role: userRole } = useAuth();

  if (!checked) {
    return <div>Loading...</div>; // Show loading indicator
  }
  if (!signedIn) {
    return <Navigate to="/" />;
  }
  if (role && userRole !== role) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
